import { PROMOTIONAL_PLANNING_PROMO_UNIT_PERCENTAGE } from "@kraftheinz/common";
import { n as normalizeComponent } from "./index.js";
import "numeral";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "list-unit-percentage" }, [_c("list-composer", { attrs: { "title": "Unit Percentage", "search-by": "KeyAccount,PpgCode", "ph-search-by": "Search by Key Account, PPG" }, scopedSlots: _vm._u([{ key: "create-buttons", fn: function(ref) {
    var onClick = ref.onClick;
    return [_c("a-button", { staticClass: "ml-2", attrs: { "type": "primary" }, on: { "click": onClick } }, [_vm._v("Add Unit %")])];
  } }]) }, [_c("url-field", { key: "KeyAccountCode", attrs: { "base-url": "/promotion-planning/unit-percentage", "data-index": "keyAccountCode", "title": "Key Account Code", "sorter": true } }), _c("text-field", { key: "KeyAccount", attrs: { "data-index": "keyAccount", "title": "Key Account", "sorter": true } }), _c("text-field", { key: "Ppg", attrs: { "data-index": "ppg", "title": "PPG", "sorter": true } }), _c("text-field", { key: "Sellin", attrs: { "data-index": "sellin", "title": "Sellin %", "sorter": true, "width": 100 } }), _c("text-field", { key: "Sellthrough", attrs: { "data-index": "sellthrough", "title": "Sellthrough %", "sorter": true, "width": 150 } }), _c("text-field", { key: "Sellout", attrs: { "data-index": "sellout", "title": "Sellout %", "sorter": true, "width": 120 } })], 1)], 1);
};
var staticRenderFns$1 = [];
const __vue2_script$1 = {
  name: "ListUnitPercentage"
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var ListUnitPercentage = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "list-unit-percentage" }, [_c("bread-crumb", { attrs: { "items": _vm.itemsMenu } }), _c("resource", { attrs: { "name": "promotion-planning.unit-percentage", "api-url": _vm.apiUrl, "create-route": "/promotion-planning/unit-percentage/create", "edit-route": "/promotion-planning/unit-percentage/:id", "page": _vm.page } }, [_c("list-unit-percentage")], 1), _c("router-view")], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: {
    ListUnitPercentage
  },
  data() {
    return {
      apiUrl,
      ListUnitPercentage,
      itemsMenu: [
        {
          title: "Administration",
          path: ""
        },
        {
          title: "Unit Percentage",
          path: "/promotional-planning/unit-percentage"
        }
      ],
      page: PROMOTIONAL_PLANNING_PROMO_UNIT_PERCENTAGE
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
